<template>
  <div class="home container-flex">
    <img alt="Vue logo" src="../assets/logo.webp" style="margin-left: auto;">
    <div class="mr-3" style="float: right; margin-right: 20px;">
      Logged in as <b>{{ this.user.email }}</b><br>
      Context : {{ this.contextName }}<br>
      <button class="btn btn-danger mt-2" @click="signout">Logout</button>
    </div>
    <div class="container-flex m-3">
      <div class="row m-3">
        <div class="col col-lg-2">
          <Sidebar></Sidebar>
        </div>
        <div class="col-lg">
          <router-view :context="this.contextID" :locale="this.defaultLocale" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue';
import firebase from 'firebase';
import { db } from '../firebase';


export default {
  components: { 
    Sidebar
  },
  name: 'Dashboard',
  async created(){
    this.state = "Default";

    //Check if user is logged in
    this.user = await firebase.auth().currentUser;
    if (this.user == null){
      await this.$router.push('/login');
      alert("You're not logged in.");
      return;
    }

    //Get context ID
    const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
    this.contextID = idTokenResult.claims.context;
    console.log("Context ID : "+idTokenResult.claims.context);

    //Get context name from ID
    const contextDoc = await db.collection('contexts').doc(this.contextID).get();
    this.contextName = contextDoc.data().name;
    this.defaultLocale = contextDoc.data().default_locale;
    console.log("Context name : "+contextDoc.data().name);
    console.log("Context locale : "+contextDoc.data().default_locale);
    
  },
  data() {
    return {
      contextID: String,
      defaultLocale: String,
      contextName: String,
      state: String,
      user: Object
    }
  },
  methods: {
    getState(state){
      this.state = state;
    },
    async signout(){
      await firebase.auth().signOut();
      await this.$router.push('/login');
      alert("You've been successfully logged out.");
    }
  }
}
</script>
