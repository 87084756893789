<template>
    <div>
        <table class="table table-striped table-hover table-bordered">
            <thead>
                <tr>
                    <th scope="col">User</th>
                    <th scope="col">Registration date</th>
                    <th scope="col">Rank</th>
                    <th scope="col">Score</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="p in this.participations" :key="p.id">
                    <th scope="row"><router-link :to="{ name: 'userDetails', params: { id: p.user.id }}">{{ p.user.first_name }} {{ p.user.last_name }}</router-link></th>
                    <td>{{ p.registration_date }}</td>
                    <td>{{ p.rank }}</td>
                    <td>{{ p.score }}</td>
                </tr>
            </tbody>
        </table>

        <nav>
            <ul class="pagination justify-content-center">
                <li class="page-item">
                <a class="page-link"  v-if="this.cursor > 1" aria-label="Previous" @click="goToPrevious()">
                    <span aria-hidden="true">&laquo;</span>
                </a>
                </li>
                <li class="page-item" v-if="this.cursor > 1" @click="goToPrevious()"><a class="page-link">{{this.cursor-1}}</a></li>
                <li class="page-item active"><a class="page-link">{{this.cursor}}</a></li>
                <li class="page-item" v-if="this.cursor < this.max_cursor-1"><a class="page-link" @click="goToNext()">{{this.cursor+1}}</a></li>
                <li class="page-item" v-if="this.cursor < this.max_cursor-1">
                <a class="page-link" aria-label="Next" @click="goToNext()">
                    <span aria-hidden="true">&raquo;</span>
                </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { db } from '@/firebase';

export default {
    name: 'UserRides',
    props: ["context", "id"],
    data() {
        return {
            baseRequest: null,
            participations: [],
            perPage: 5,
            cursor: 1,
            requests: [],
            max_cursor: 1
        }
    },
    async created() {
        this.baseRequest = db.collection('contexts').doc(this.context).collection('participations').where("challenge", "==", this.id).orderBy("registration_date", "desc");
        //Get challenges count
        await this.getMaxCursor();
        //Retrieve users list from Firestore
        this.requests.push(this.baseRequest);
        this.getParticipations(this.requests[this.cursor-1], 'UP');
    },
    methods: {
        async getParticipations(req, direction) {
            await req.limit(this.perPage).get().then(docSnapshot => {
                this.participations = [];
                docSnapshot.forEach(doc => {
                    const participation = doc.data();
                    participation.id = doc.id;
                    participation.registration_date = doc.data().registration_date.toDate().toDateString();
                    this.participations.push(participation);
                });

                //Build next query
                var lastVisible = docSnapshot.docs[docSnapshot.docs.length-1];
                if (direction == 'UP'){
                    this.requests.push(this.baseRequest.startAfter(lastVisible).limit(this.perPage));
                }

            }, err => {
            console.log(`Encountered error: ${err}`);
            });

            for (const p of this.participations){
                await db.collection('contexts').doc(this.context).collection('users').doc(p.user).get().then(doc => {
                p.user = doc.data();
                p.user.id = doc.id;
            });
            }
        },
        goToNext(){
            this.cursor++;
            this.getParticipations(this.requests[this.cursor-1], 'UP');
        },
        goToPrevious(){
            this.cursor--;
            this.getParticipations(this.requests[this.cursor-1], 'DOWN');
        },
        async getMaxCursor(){
            const snapshot = await this.baseRequest.get();
            const participations_count = snapshot.size;
            this.max_cursor = (participations_count/this.perPage)+1;
            console.log('Max cursor : '+this.max_cursor);
        }
    }
}
</script>

<style>

</style>