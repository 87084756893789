<template>
  <div>
      <h2>Store</h2>
      <router-link to="store/new" tag="button" class="btn m-3 btn-primary">Create Product</router-link>
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col">Offer</th>
            <th scope="col">Brand</th>
            <th scope="col">From</th>
            <th scope="col">To</th>
            <th scope="col">Transactions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-bind:class="(this.isActive(product.start_date, product.end_date))?'table-success':'table-default'" v-for="product in this.products" :key="product.id" @click="goToProduct(product.id)">
            <th scope="row">{{ product.title }}</th>
            <td>{{ product.brand }}</td>
            <td>{{ product.start_date.toDate().toDateString() }}</td>
            <td>{{ product.end_date.toDate().toDateString() }}</td>
            <td>{{ product.transactions }}</td>
          </tr>
        </tbody>
      </table>

      <nav>
            <ul class="pagination justify-content-center">
                <li class="page-item">
                <a class="page-link"  v-if="this.cursor > 1" aria-label="Previous" @click="goToPrevious()">
                    <span aria-hidden="true">&laquo;</span>
                </a>
                </li>
                <li class="page-item" v-if="this.cursor > 1" @click="goToPrevious()"><a class="page-link">{{this.cursor-1}}</a></li>
                <li class="page-item active"><a class="page-link">{{this.cursor}}</a></li>
                <li class="page-item" v-if="this.cursor < this.max_cursor-1"><a class="page-link" @click="goToNext()">{{this.cursor+1}}</a></li>
                <li class="page-item" v-if="this.cursor < this.max_cursor-1">
                <a class="page-link" aria-label="Next" @click="goToNext()">
                    <span aria-hidden="true">&raquo;</span>
                </a>
                </li>
            </ul>
        </nav>
  </div>
</template>

<script>
import { db } from '@/firebase';

export default {
    name: 'Store',
    props: ["context", "locale"],
    async created() {
      //Get product count
        await this.getMaxCursor();
      //Retrieve products list
      this.requests.push(db.collection('contexts').doc(this.context).collection('products').orderBy("start_date", "desc"));
      await this.getProducts(this.requests[this.cursor-1], 'UP');
      
    },
    data() {
        return {
            products: [],
            perPage: 10,
            cursor: 1,
            requests: [],
            max_cursor: 1,
        }
    },
    methods: {
      async getProducts(req, direction) {
        await req.limit(this.perPage).get().then(docSnapshot => {
            console.log('Products list refreshed.');
            this.products = [];
            docSnapshot.forEach(doc => {
                const product = doc.data();
                product.id = doc.id;
                product.title = product.title[this.locale];
                this.products.push(product);
            });
            this.getTransactionsCount();

            //Build next query
                var lastVisible = docSnapshot.docs[docSnapshot.docs.length-1];
                if (direction == 'UP'){
                    this.requests.push(db.collection('contexts').doc(this.context).collection('products').orderBy("start_date", "desc").startAfter(lastVisible).limit(this.perPage));
                }
        }, err => {
        console.log(`Encountered error: ${err}`);
        });
      },
      async getTransactionsCount() {
        const transactionsRef = db.collection('contexts').doc(this.context).collection('transactions');
        await transactionsRef.onSnapshot((docSnapshot) => {
          docSnapshot.forEach(element => {
            for (const product of this.products){
              if (product.transactions == undefined){
                product.transactions = 0;
              }
              if (product.id == element.data().product){
                product.transactions++;
              }
            }
          });
        });
      },
      goToProduct(id){
        this.$router.push({ name: 'productDetails', params: { id: id } });
      },
      goToNext(){
          this.cursor++;
          this.getProducts(this.requests[this.cursor-1], 'UP');
      },
      goToPrevious(){
          this.cursor--;
          this.getProducts(this.requests[this.cursor-1], 'DOWN');
      },
      async getMaxCursor(){
          const snapshot = await db.collection('contexts').doc(this.context).collection('products').get();
          const users_count = snapshot.size;
          this.max_cursor = (users_count/this.perPage)+1;
          console.log('Max cursor : '+this.max_cursor);
      },
      isActive(fDate1, fDate2){
        const now = new Date();
        if (now > fDate1.toDate() && now < fDate2.toDate()){
          return true;
        }
        return false;
      }
    }
}
</script>

<style>
tr {
    cursor: pointer;
}
</style>