<template>
  <div>
    <h3 class="mb-3">User details</h3>
    <div class="container">
      <div class="row mb-3">
        <div class="col">
          <p><b>UID</b> : {{$route.params.id}}</p>
        </div>
        <div class="col">
          <p><b>e-mail</b> : {{this.user.email}}</p>
        </div>
        <div class="col">
          <p><b>Full name</b> : {{this.user.first_name}} {{this.user.last_name}}</p>
        </div>
        <div class="col">
          <p><b>Last login</b> : {{this.user.last_login}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p><b>XP</b> : {{this.user.xp}}</p><button @click="this.triggerXPModal = true" class="btn btn-outline-primary">Give XP</button>
        </div>
        <div class="col">
          <p><b>eCoins</b> : {{this.user.ecoins}}</p>
          <div class="btn-group" role="group">
            <button @click="this.triggerWithdrawECoinsModal = true" class="btn btn-outline-danger">Withdraw eCoins</button>
            <button @click="this.triggerECoinsModal = true" class="btn btn-outline-primary">Give eCoins</button>
          </div>
        </div>
        <div class="col">
          <p><b>Level</b> : {{this.user.level}}</p>
        </div>
        <div class="col">
          <p v-if="this.user.accept_notifications">Currently <b>accepting</b> notifications</p>
          <p v-else>Currently <b>not accepting</b> notifications</p>
        </div>
      </div>
    </div>

    <ul class="nav nav-tabs mt-4">
      <li class="nav-item">
        <a v-if="this.tabComponent!='UserRides'" class="nav-link" @click="this.switchTab('UserRides')">Rides</a>
        <a v-else class="nav-link active">Rides</a>
      </li>
      <li class="nav-item">
        <a v-if="this.tabComponent!='UserTransactions'" class="nav-link" @click="this.switchTab('UserTransactions')">Transactions</a>
        <a v-else class="nav-link active">Transactions</a>
      </li>
    </ul>
    <component
      :is="this.tabComponent"
      :context="this.context"
      :uid="this.$route.params.id">
    </component>

    <Modal v-if="triggerXPModal" 
      v-on:sendFieldValue="addXP"
      v-on:cancelModal="this.triggerXPModal = false"
      :title="'Add XP to '+this.user.first_name+' '+this.user.last_name"
      :message="'Please do not forget that 10 XP = 1 eCoin '"
      :error="''"
    />

    <Modal v-if="triggerECoinsModal" 
      v-on:sendFieldValue="addECoins"
      v-on:cancelModal="this.triggerECoinsModal = false"
      :title="'Add eCoins to '+this.user.first_name+' '+this.user.last_name"
      :message="'You can add as many eCoins as you want.'"
      :error="''"
      :field="true"
    />

    <Modal v-if="triggerWithdrawECoinsModal" 
      v-on:sendFieldValue="withdrawECoins"
      v-on:cancelModal="this.triggerWithdrawECoinsModal = false"
      :title="'Withdraw eCoins to '+this.user.first_name+' '+this.user.last_name"
      :message="'You can withdraw as many eCoins as you want. The minimum account balance will be set to 0.'"
      :error="''"
      :field="true"
    />
  </div>
</template>

<script>
import { db } from '@/firebase';
import Modal from '@/components/Modal.vue';
import UserRides from '@/components/UserRides.vue';
import UserTransactions from '@/components/UserTransactions.vue';
import { shallowRef } from 'vue';

export default {
  components: { Modal, UserRides, UserTransactions },
  name: 'UserDetails',
  props: ["context"],
  data() {
    return {
      user: [],
      curve: [],
      rides: [],
      products: [],
      transactions: [],
      triggerXPModal: Boolean,
      triggerECoinsModal: Boolean,
      triggerWithdrawECoinsModal: Boolean,
      tabComponent: shallowRef('UserRides')
    }
  },
  async created() {
    this.triggerXPModal = false;
    this.triggerECoinsModal = false;
    this.triggerWithdrawECoinsModal = false;
    await this.getUserData(this.$route.params.id);
    await this.getProgressCurve();
  },
  methods: {
    async getUserData(uid){
      //Get data from firestore
      const userRef = db.collection('contexts').doc(this.context).collection('users').doc(uid);
      await userRef.onSnapshot(docSnapshot => {
        this.user = docSnapshot.data();
        this.user.last_login = docSnapshot.data().last_login.toDate().toDateString();
        this.user.uid = uid;
      });
      
    },
    async getProgressCurve() {
      const curveRef = db.collection('contexts').doc(this.context);
      await curveRef.onSnapshot(docSnapshot => {
        this.curve = docSnapshot.data().progress_curve;
      });
    },
    async addXP(value){
      console.log(value);
      if (value > 0){
        const newLevel = this.getLevelWithXP(this.user.xp+value);
        const newBalance = parseInt(this.user.ecoins) + parseInt(this.getECoinsWithXP(value));
        const userRef = db.collection('contexts').doc(this.context).collection('users').doc(this.$route.params.id);
        userRef.update({
          xp: this.user.xp+value, 
          level:newLevel,
          ecoins: newBalance
        });
      }
      this.triggerXPModal = false;
    },
    getLevelWithXP(xp){
      let sum = 0;
      let i = 0;
      while (sum < xp){
        sum += this.curve[i];
        i++;
      }
      return i-1;
    },
    getECoinsWithXP(xp){
      return Math.floor(xp/10);
    },
    async addECoins(value){
      console.log(value);
      if (value > 0){
        const newBalance = parseInt(this.user.ecoins) + parseInt(value);
        const userRef = db.collection('contexts').doc(this.context).collection('users').doc(this.$route.params.id);
        userRef.update({
          ecoins: newBalance
        });
      }
      this.triggerECoinsModal = false;
    },
    async withdrawECoins(value){
      console.log(value);
      if (value > 0){
        const newBalance = parseInt(this.user.ecoins) - parseInt(value);
        const userRef = db.collection('contexts').doc(this.context).collection('users').doc(this.$route.params.id);
        userRef.update({
          ecoins: Math.max(newBalance, 0)
        });
      }
      this.triggerWithdrawECoinsModal = false;
    },
    switchTab(component){
      this.tabComponent=shallowRef(component);
    }
  }
}
</script>

<style>

</style>