<template>
    <table class="table table-striped table-hover table-bordered">
        <thead>
            <tr>
                <th scope="col">Brand</th>
                <th scope="col">Date</th>
                <th scope="col">Offer</th>
                <th scope="col">Voucher</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="transaction in this.transactions" :key="transaction.id">
                <th scope="row">{{ transaction.brand }}</th>
                <td>{{ transaction.date.toDate().toDateString() }}</td>
                <td><router-link :to="{ name: 'productDetails', params: { id: transaction.product }}">{{ transaction.title }}</router-link></td>
                <td>{{ transaction.code }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { db } from '@/firebase';

export default {
    name: 'UserTransactions',
    props: ["context", "uid"],
    data() {
        return {
            transactions: [],
            products: []
        }
    },
    async created() {
        await this.getTransactionsData();
    },
    methods: {
        async getTransactionsData(){
            const productsRef = db.collection('contexts').doc(this.context).collection('products');
            const products = await productsRef.get();
            products.forEach(element => {
                let product = element.data();
                product.id = element.id;
                this.products.push(product);
            });

            const transactionsRef = db.collection('contexts').doc(this.context).collection('transactions').where('owner', '==', this.uid);
            await transactionsRef.onSnapshot(querySnapshot => {
                this.transactions = [];
                querySnapshot.forEach(transDoc => {
                let transaction = transDoc.data();
                const product = this.getProductByID(transaction.product);
                transaction.brand = product.brand;
                transaction.title = product.title;
                this.transactions.push(transaction);
                })
            });
        },
        getProductByID(id){
            for (const p of this.products){
                if (p.id == id){
                return p;
                }
            }
            return null;
        }
    }
}
</script>

<style>

</style>