<template>
    <div class="container">
        <h2>Create challenge</h2>
        <form  style="text-align: left;">
            <div class="row g-3">
                <div class="col mb-3">
                    <locale-selector 
                    :context="this.context"
                    v-on:sendLocales="getLocales"></locale-selector>
                </div>
            </div>
            <div class="row g-3">
                <div class="col mb-3">
                    <label for="title" class="form-label">Title</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="challenge.title[this.activeLocale]" name="title">
                        <span class="input-group-text">{{ this.shortActiveLocale }}</span>
                    </div>
                </div>
                <div class="col mb-3">
                    <label for="brand" class="form-label">Brand</label>
                    <input type="text" class="form-control" v-model="challenge.brand" name="brand" max="15">
                </div>
            </div>

            <div class="row g-3">
                <div class="col mb-3">
                    <label class="form-label mb-1">Unlock type</label>
                    <div class="mb-2">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" value="FREE" v-model="challenge.unlock_type" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked>
                            <label class="form-check-label" for="flexRadioDefault1">Free access</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" value="CODE" v-model="challenge.unlock_type" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                            <label class="form-check-label" for="flexRadioDefault2">Code</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" value="ECOINS" v-model="challenge.unlock_type" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                            <label class="form-check-label" for="flexRadioDefault2">eCoins</label>
                        </div>
                    </div>
                    
                    <div v-if="this.challenge.unlock_type == 'CODE'" class="col">
                        <label for="code" class="form-label">Code</label>
                        <input type="text" class="form-control" v-model="challenge.code" name="code" max="10">
                    </div>
                    <div v-if="this.challenge.unlock_type == 'ECOINS'" class="col">
                        <label for="code" class="form-label">Price</label>
                        <input type="number" class="form-control" v-model="challenge.price" name="code" min="0">
                    </div>
                </div>
                <div class="col mb-3">
                    <label for="description" class="form-label">Description</label>
                    <div class="input-group">
                        <textarea class="form-control" rows="3" cols="60" style="resize: none;" v-model="challenge.description[this.activeLocale]" name="description"></textarea>
                        <span class="input-group-text">{{ this.shortActiveLocale }}</span>
                    </div>
                </div>
            </div>

            <div class="row g-3">
                <div class="col mb-3">
                    <label for="rules_url" class="form-label">Rules URL</label>
                    <input type="text" class="form-control" name="rules_url" id="rules_url" v-model="challenge.rules_url">
                </div>
                <div class="col mb-3">
                    <label for="partner_url" class="form-label">Partner URL</label>
                    <input type="text" class="form-control" name="partner_url" id="partner_url" v-model="challenge.partner_url">
                </div>
            </div>

            <div class="row g-3">
                <div class="col mb-3">
                    <div class="row g-3 mb-4">
                        <div class="col mb-3">
                            <label for="start_date" class="form-label">Start date</label>
                            <input type="date" class="form-control" name="start_date" id="start_date" v-model="challenge.start_date" style="width: 70%">
                        </div>
                        <div class="col mb-3">
                            <label for="end_date" class="form-label">End date</label>
                            <input type="date" class="form-control" name="end_date" id="end_date" v-model="challenge.end_date" style="width: 70%">
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col">
                            <label for="icon" class="form-label">Icon</label>
                            <select name="icon" class="form-select mb-3" v-model="challenge.icon" required>
                                <option value="distance">Distance</option>
                                <option value="tyre">Tyre</option>
                                <option value="maintenance">Maintenance</option>
                                <option value="tools">Tools</option>
                                <option value="location">location</option>
                                <option value="duration">Duration</option>
                                <option value="fuelEv">Electric Vehicle</option>
                                <option value="fuelGasoline">Gasoline</option>
                                <option value="fuelDiesel">Diesel</option>
                                <option value="fuelLpg">LPG</option>
                                <option value="fuelHybrid">Hybrid</option>
                            </select>
                        </div>
                    </div>
                    <div class="row g-3" style="text-align: center;">
                        <label class="form-label">Color</label>
                        <div class="col mb-3">
                            <input type="radio" class="btn-check" name="options" id="blue" autocomplete="off" @click="this.challenge.color = 'blue'" :disabled="this.challenge.color == 'blue' ? true: false">
                            <label class="btn btn-primary" for="blue">Blue</label>
                        </div>
                        <div class="col mb-3">
                            <input type="radio" class="btn-check" name="options" id="yellow" autocomplete="off" @click="this.challenge.color = 'yellow'" :disabled="this.challenge.color == 'yellow' ? true: false">
                            <label class="btn" style="background-color: yellow;" for="yellow">Yellow</label>
                        </div>
                        <div class="col mb-3">
                            <input type="radio" class="btn-check" name="options" id="green" autocomplete="off" @click="this.challenge.color = 'green'" :disabled="this.challenge.color == 'green' ? true: false">
                            <label class="btn btn-success" for="green">Green</label>
                        </div>
                        <div class="col mb-3">
                            <input type="radio" class="btn-check" name="options" id="orange" autocomplete="off" @click="this.challenge.color = 'orange'" :disabled="this.challenge.color == 'orange' ? true: false">
                            <label class="btn btn-warning" for="orange">Orange</label>
                        </div>
                    </div>
                </div>
                <div class="col mb-3">
                    <div class="mb-3">
                        <label for="logo" class="form-label">Logo</label>
                        <input type="file" class="form-control" name="logo" id="logo" @change="onLogoSelected">
                    </div>
                    <div class="row g-3">
                        <div class="col mb-3" style="text-align: center;">
                            <label class="form-label">Logo preview</label><br>
                            <img :src="logoURL" style="width: 70%;">
                        </div>
                    </div>
                </div>
            </div>

        </form>
        <div v-if="this.errors.length != 0" div class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Please fix the following errors before creating a new challenge</h4>
            <hr>
            <p v-for="error in this.errors" :key="error">{{ error }}</p>
        </div>
        <button v-if="!this.submitted" class="btn btn-primary" v-on:click="this.createChallenge()">Create challenge</button>
        <div v-else class="spinner-border text-success"></div>
    </div>
</template>

<script>
import LocaleSelector from '@/components/LocaleSelector.vue';
import { storage } from '@/firebase';
import { db } from '../../../firebase';

export default {
    name: 'CreateChallenge',
    props: ["context"],
    components: {LocaleSelector},
    data() {
        return {
            locales: [],
            activeLocale: '',
            submitted: false,
            errors: [],
            logoURL: "",
            selectedLogo: null,
            challenge: {
                title: {},
                brand: '',
                description: {},
                unlock_type: '',
                code: '',
                price: 0,
                color: 'blue',
                icon: 'distance',
                start_date: null,
                end_date: null
            }
        }
    },
    computed: {
        shortActiveLocale() {
            return this.activeLocale.replace('_', ' - ');
        }
    },
    methods: {
        onLogoSelected(event){
            this.selectedLogo = event.target.files[0];
            this.logoURL= URL.createObjectURL(this.selectedLogo);
        },
        async createChallenge(){
            this.submitted = true;
            //Control fields integrity
            this.checkChallenge();
            if (this.errors.length != 0){
                this.submitted = false;
                return;
            }

            //Set Firebase refs
            const imageRef = storage.ref().child(this.context+'/challenges');
            const dbRef = db.collection('contexts').doc(this.context).collection('challenges');

            //Build file names
            const fireStoreID = (this.challenge.brand+'-'+this.makeid(19)).slice(0,20).replace(' ', '_');
            const logoName = fireStoreID+'_logo.'+this.selectedLogo.name.substring(this.selectedLogo.name.lastIndexOf('.') + 1);
            console.log('ID : '+fireStoreID);
            console.log('Logo : '+logoName);

            //Upload images
            var logoRef = imageRef.child('logos/'+logoName);
            await logoRef.put(this.selectedLogo).then((snapshot) => {
                console.log('Uploaded logo !');
                console.log(snapshot);
            });
            this.challenge.logo_url = await logoRef.getDownloadURL();

            //Handle dates
            const start_date = this.challenge.start_date;
            this.challenge.start_date = new Date(start_date);
            const end_date = this.challenge.end_date;
            this.challenge.end_date = new Date(end_date);

            await dbRef.doc(fireStoreID).set(this.challenge);
            console.log(this.challenge);

            if (this.challenge.unlock_type == 'ECOINS'){
                this.challenge.price = parseInt(this.challenge.price);
            }

            await this.$router.push('/dashboard/challenges');
            alert("Challenge successfully created !");
            this.submitted = false;
            

        },
        checkChallenge() {
            this.errors = [];
            const textFields = ['brand', 'rules_url', 'partner_url'];
            for (const textField of textFields){
                if (this.challenge[textField] == ''){
                    this.errors.push("Field '"+textField+"' cannot be empty.");
                }
            }

            const supportedExt = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG'];
            if (this.logoURL == ""){
                this.errors.push("You must upload a challenge logo.");
            }else{
                const logoExt = this.selectedLogo.name.substring(this.selectedLogo.name.lastIndexOf('.') + 1);
                if (!supportedExt.includes(logoExt)){
                    this.errors.push("Logo image type unsupported.");
                }
            }
            
            if  (this.challenge.start_date == null){
                this.errors.push("Please enter a valid start date.");
            }
            if  (this.challenge.end_date == null){
                this.errors.push("Please enter a valid end date.");
            }
            if  (this.challenge.start_date >= this.challenge.end_date){
                this.errors.push("End date must be after start date.");
            }

            if (this.challenge.unlock_type == 'CODE' && this.challenge.code == ''){
                this.errors.push("Please enter a valid code.");
            }
            if (this.challenge.unlock_type == 'ECOINS' && this.challenge.price == null){
                this.errors.push("Please enter a valid price.");
            }
        },
        makeid(length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        addOption(id){
            let i = 0;
            this.options.forEach(option => {
                if (option.id == id){
                    this.challenge.categories.push(option);
                    this.options.splice(i, 1);
                }
                i++;
            });
        },
        removeOption(id){
            let i = 0;
            this.challenge.categories.forEach(option => {
                if (option.id == id){
                    this.options.push(option);
                    this.challenge.categories.splice(i, 1);
                }
                i++;
            });
        },
        findNameByID(id){
            for(const option of this.options){
                if (option.id == id){
                    return option.name;
                }
            }
        },
        getLocales(value){
            const localesBundle = value;
            this.locales = localesBundle.selectedLocales;
            this.activeLocale = localesBundle.activeLocale;
        }
    }
}
</script>

<style>

</style>