<template>
    <div class="container">
        <h2>Edit product</h2>
        <form  style="text-align: left;">
            <div class="row g-3">
                <div class="col mb-3">
                    <label for="title" class="form-label">Title</label>
                    <div class="input-group input-group-sm mb-1"  v-for="(value, key, index) in this.product.title" :key="index">
                        <input type="text" class="form-control" v-model="this.product.title[key]" name="title">
                        <span class="input-group-text">{{key.replace('_', ' - ')}}</span>
                    </div>
                </div>
                <div class="col mb-3">
                    <label for="text_link" class="form-label">Text Link</label>
                    <div class="input-group input-group-sm mb-1"  v-for="(value, key, index) in this.product.text_link" :key="index">
                        <input type="text" class="form-control" v-model="this.product.text_link[key]" name="text_link">
                        <span class="input-group-text">{{key.replace('_', ' - ')}}</span>
                    </div>
                </div>
            </div>

            <div class="row g-3">
                <div class="col mb-3">
                    <div class="mb-3">
                        <label for="brand" class="form-label">Brand</label>
                        <input type="text" class="form-control" v-model="product.brand" name="brand" max="15">
                    </div>
                    
                    <div class="form-check mb-3">
                        <input type="checkbox" class="form-check-input" name="eco_friendly" id="eco_friendly" v-model="product.eco_friendly">
                        <label for="eco_friendly" class="form-check-label">Eco-Friendly</label>
                    </div>

                    <div class="form-check mb-3">
                        <input type="checkbox" class="form-check-input" name="sponsored" id="sponsored" v-model="product.sponsored">
                        <label for="sponsored" class="form-check-label">Sponsored</label>
                    </div>
                </div>
                <div class="col mb-3">
                    <label for="description" class="form-label">Description</label>
                    <div class="input-group input-group-sm mb-1"  v-for="(value, key, index) in this.product.description" :key="index">
                        <textarea class="form-control form-control-sm" rows="2" cols="60" style="resize: none;" v-model="product.description[key]" name="description"></textarea>
                        <span class="input-group-text">{{key.replace('_', ' - ')}}</span>
                    </div>
                </div>
            </div>

            <div class="row g-3">
                <div class="col mb-3">
                    <label for="price" class="form-label">Price</label>
                    <input type="number" class="form-control" name="price" id="price" min="0" v-model="product.price">
                </div>
                <div class="col mb-3">
                    <label for="url" class="form-label">URL</label>
                    <input type="text" class="form-control" name="url" id="url" v-model="product.url">
                </div>
            </div>

            <div class="row g-3">
                <div class="col mb-3">
                    <div class="row g-3 mb-4">
                        <div class="col mb-3">
                            <label for="start_date" class="form-label">Start date</label>
                            <input type="date" class="form-control" name="start_date" id="start_date" v-model="product.start_date" style="width: 70%">
                        </div>
                        <div class="col mb-3">
                            <label for="end_date" class="form-label">End date</label>
                            <input type="date" class="form-control" name="end_date" id="end_date" v-model="product.end_date" style="width: 70%">
                        </div>
                    </div>
                    <div class="row g-3">
                        <label for="categories" class="form-label" style="text-align: center;">Available categories</label>
                        <div name="categories" class="d-grid d-md-block">
                            <button v-for="option in this.options" :key="option.id" @click="addOption(option.id)" class="btn btn-outline-primary rounded-pill m-1">{{ option.name }}</button>
                        </div>
                        <label for="categories" class="form-label" style="text-align: center;">Selected categories</label>
                        <div class="d-grid d-md-block mb-3">
                            <p v-if="this.selected_options.length == 0">No categories selected.</p>
                            <button v-for="option in this.selected_options" :key="option.id" @click="removeOption(option.id)" class="btn btn-primary rounded-pill m-1">{{ option.name }}</button>
                        </div>
                    </div>
                </div>
                <div class="col mb-3">
                    <div class="mb-3">
                        <label for="logo" class="form-label">Logo</label>
                        <input type="file" class="form-control" name="logo" id="logo" @change="onLogoSelected">
                    </div>
                    <div class="mb-3">
                        <label for="pic" class="form-label">Picture</label>
                        <input type="file" class="form-control" name="pic" id="pic" @change="onPictureSelected">
                    </div>
                    <div class="row g-3">
                        <div class="col mb-3" style="text-align: center;">
                            <label class="form-label">Logo preview</label><br>
                            <img :src="logoURL" style="width: 70%;">
                        </div>
                        <div class="col mb-3" style="text-align: center;">
                            <label class="form-label">Picture preview</label><br>
                            <img :src="picURL" style="width: 70%;">
                        </div>
                    </div>
                </div>
            </div>

        </form>
        <div v-if="this.errors.length != 0" div class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Please fix the following errors before creating a new product</h4>
            <hr>
            <p v-for="error in this.errors" :key="error">{{ error }}</p>
        </div>
        <button v-if="!this.submitted" class="btn btn-primary" v-on:click="this.editProduct()">Edit Product</button>
        <div v-else class="spinner-border text-success"></div>
    </div>
</template>

<script>
import { db, storage } from '@/firebase';

export default {
    name: 'EditProduct',
    props: ["context"],
    data() {
        return{
            submitted: false,
            errors: [],
            logoURL: "",
            selectedLogo: [],
            selectedPicture: [],
            picURL: "",
            product: [],
            options: [{name:'Accessories', id:'ACCESSORIES'},
                {name:'Auto-Moto', id:'AUTO_MOTO'},
                {name:'Well-Being', id:'BEAUTY_WELL_BEING'},
                {name:'Leisure', id:'LEISURE_CULTURE'},
                {name:'Kids', id:'KIDS'},
                {name:'Gardens & Flowers', id:'GARDEN_FLOWERS'},
                {name:'Home', id:'HOME'},
                {name:'Fashion', id:'FASHION'},
                {name:'Food', id:'FOOD'},
                {name:'Trips', id:'TRIP'}
            ],
            selected_options: []
        }
    },
    async created() {
        await this.getProductData();
        this.logoURL= this.product.logo_url;
        this.picURL= this.product.picture_url;
    },
    methods: {
        async getProductData(){
            const productRef = db.collection('contexts').doc(this.context).collection('products').doc(this.$route.params.id);
            await productRef.get().then(snapshot => {
                this.product = snapshot.data();
                const sDate = snapshot.data().start_date.toDate();
                const eDate = snapshot.data().end_date.toDate();
                this.product.start_date = sDate.getUTCFullYear()+'-'+('0'+(sDate.getUTCMonth()+1)).slice(-2)+'-'+('0'+sDate.getUTCDate()).slice(-2);
                this.product.end_date = eDate.getUTCFullYear()+'-'+('0'+(eDate.getUTCMonth()+1)).slice(-2)+'-'+('0'+eDate.getUTCDate()).slice(-2);
                this.product.id = snapshot.id;
            });

            //Pre-fill categories
            this.product.categories.forEach(cat => {
                this.addOption(cat);
            });
        },
        addOption(id){
            let i = 0;
            this.options.forEach(option => {
                if (option.id == id){
                    this.selected_options.push(option);
                    this.options.splice(i, 1);
                }
                i++;
            });
        },
        removeOption(id){
            let i = 0;
            this.selected_options.forEach(option => {
                if (option.id == id){
                    this.options.push(option);
                    this.selected_options.splice(i, 1);
                }
                i++;
            });
        },
        onLogoSelected(event){
            this.selectedLogo = event.target.files[0];
            this.logoURL= URL.createObjectURL(event.target.files[0]);
        },
        onPictureSelected(event){
            this.selectedPicture = event.target.files[0];
            this.picURL= URL.createObjectURL(event.target.files[0]);
        },
        checkProduct() {
            this.errors = [];
            console.log('Checking product data...');
            const textFields = ['brand', 'url'];
            for (const textField of textFields){
                if (this.product[textField] == ''){
                    this.errors.push("Field '"+textField+"' cannot be empty.");
                }
            }
            if (this.product.price < 0){
                    this.errors.push("Price cannot be a negative value.");
            }
            if (this.selected_options == 0){
                this.errors.push("Please select at least one category.");
            }
            if  (this.product.start_date == ""){
                this.errors.push("Please enter a valid start date.");
            }
            if  (this.product.end_date == ""){
                this.errors.push("Please enter a valid end date.");
            }
            if  (this.product.start_date >= this.product.end_date){
                this.errors.push("End date must be after start date.");
            }
        },
        async editProduct() {
            this.submitted = true;
            this.checkProduct();
            if (this.errors.length != 0){
                this.submitted = false;
                return;
            }

            //Check for new images
            const imageRef = storage.ref().child(this.context+'/products');

            //LOGO
            if (this.logoURL == this.product.logo_url){
                console.log("Logo doesn't change");
            }else{
                //Delete previous image
                const oldLogoRef = storage.refFromURL(this.product.logo_url);
                await oldLogoRef.delete().then(() => {
                    console.log("Old logo successfully deleted.");
                }).catch(() => {
                    console.log("Old logo already deleted.");
                });

                //Add new image
                const logoName = this.product.id+'_logo.'+this.selectedLogo.name.substring(this.selectedLogo.name.lastIndexOf('.') + 1);
                var logoRef = imageRef.child('logos/'+logoName);
                await logoRef.put(this.selectedLogo).then((snapshot) => {
                    console.log('Uploaded new logo !');
                    console.log(snapshot);
                });
                this.logoURL = await logoRef.getDownloadURL();
            }

            //PICTURE
            if (this.picURL == this.product.picture_url){
                console.log("Picture doesn't change");
            }else{
                //Delete previous image
                const oldPictureRef = storage.refFromURL(this.product.picture_url);
                await oldPictureRef.delete().then(() => {
                    console.log("Old picture successfully deleted.");
                }).catch(() => {
                    console.log("Old picture already deleted.");
                });

                //Add new image
                const picName = this.product.id+'_picture.'+this.selectedPicture.name.substring(this.selectedPicture.name.lastIndexOf('.') + 1);
                var picRef = imageRef.child('logos/'+picName);
                await picRef.put(this.selectedPicture).then((snapshot) => {
                    console.log('Uploaded new picture !');
                    console.log(snapshot);
                });
                this.picURL = await picRef.getDownloadURL();
            }

            //Handle categories
            this.product.categories = [];
            this.selected_options.forEach(option => {
                this.product.categories.push(option.id);
                console.log('OPTION : '+option.id);
            });

            const dbRef = db.collection('contexts').doc(this.context).collection('products');
            await dbRef.doc(this.$route.params.id).update({
                title: this.product.title,
                brand: this.product.brand,
                description: this.product.description,
                text_link: this.product.text_link,
                start_date: new Date(this.product.start_date),
                end_date: new Date(this.product.end_date),
                price: parseInt(this.product.price),
                eco_friendly: this.product.eco_friendly,
                categories: this.product.categories,
                logo_url: this.logoURL,
                picture_url: this.picURL

            });
            await this.$router.push('/dashboard/store');
            alert("Product successfully updated !");
            
        }
    }
}
</script>

<style>

</style>