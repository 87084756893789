<template>
    <div>
        <h2>Challenges</h2>
        <router-link to="challenges/new" tag="button" class="btn m-3 btn-primary">Create Challenge</router-link>
        <table class="table table-hover table-bordered">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Start date</th>
                    <th scope="col">End date</th>
                    <th scope="col">Code/Price</th>
                </tr>
            </thead>
            <tbody>
                <tr v-bind:class="(this.isActive(challenge.start_date, challenge.end_date))?'table-success':'table-default'" v-for="challenge in this.challenges" :key="challenge.id" @click="this.goToChallenge(challenge.id)">
                    <th scope="row">{{ challenge.title }}</th>
                    <td>{{ challenge.start_date.toDate().toDateString() }}</td>
                    <td>{{ challenge.end_date.toDate().toDateString() }}</td>
                    <td v-if="challenge.unlock_type == 'CODE'">{{ challenge.code }}</td>
                    <td v-else-if="challenge.unlock_type == 'ECOINS'">{{ challenge.price }} eCoins</td>
                    <td v-else>Free access</td>
                </tr>
            </tbody>
        </table>

        <nav>
            <ul class="pagination justify-content-center">
                <li class="page-item">
                <a class="page-link"  v-if="this.cursor > 1" aria-label="Previous" @click="goToPrevious()">
                    <span aria-hidden="true">&laquo;</span>
                </a>
                </li>
                <li class="page-item" v-if="this.cursor > 1" @click="goToPrevious()"><a class="page-link">{{this.cursor-1}}</a></li>
                <li class="page-item active"><a class="page-link">{{this.cursor}}</a></li>
                <li class="page-item" v-if="this.cursor < this.max_cursor-1"><a class="page-link" @click="goToNext()">{{this.cursor+1}}</a></li>
                <li class="page-item" v-if="this.cursor < this.max_cursor-1">
                <a class="page-link" aria-label="Next" @click="goToNext()">
                    <span aria-hidden="true">&raquo;</span>
                </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { db } from '@/firebase';

export default {
    name: 'challenges',
    props: ["context", "locale"],
    data() {
        return {
            baseRequest: null,
            challenges: [],
            perPage: 10,
            cursor: 1,
            requests: [],
            max_cursor: 1
        }
    },
    async created() {
        this.baseRequest = db.collection('contexts').doc(this.context).collection('challenges').orderBy("end_date", "desc");
        //Get challenges count
        await this.getMaxCursor();
        //Retrieve users list from Firestore
        this.requests.push(this.baseRequest);
        this.getChallenges(this.requests[this.cursor-1], 'UP');
    },
    methods: {
        async getChallenges(req, direction) {
            await req.limit(this.perPage).get().then(docSnapshot => {
                console.log('Users list refreshed.');
                this.challenges = [];
                docSnapshot.forEach(doc => {
                    const challenge = doc.data();
                    challenge.id = doc.id;
                    challenge.title = challenge.title[this.locale];
                    this.challenges.push(challenge);
                });

                //Build next query
                var lastVisible = docSnapshot.docs[docSnapshot.docs.length-1];
                if (direction == 'UP'){
                    this.requests.push(this.baseRequest.startAfter(lastVisible).limit(this.perPage));
                }

            }, err => {
            console.log(`Encountered error: ${err}`);
            });
        },
        async getMaxCursor(){
            const snapshot = await this.baseRequest.get();
            const challenges_count = snapshot.size;
            this.max_cursor = (challenges_count/this.perPage)+1;
            console.log('Max cursor : '+this.max_cursor);
        },
        goToNext(){
            this.cursor++;
            this.getChallenges(this.requests[this.cursor-1], 'UP');
        },
        goToPrevious(){
            this.cursor--;
            this.getChallenges(this.requests[this.cursor-1], 'DOWN');
        },
        goToChallenge(id){
            this.$router.push({ name: 'challengeDetails', params: { id: id } });
        },
        isActive(fDate1, fDate2){
            const now = new Date();
            if (now > fDate1.toDate() && now < fDate2.toDate()){
                return true;
            }
            return false;
        }
    }
}
</script>

<style>
tr {
    cursor: pointer;
}
</style>