<template>
  <div>
    <div class="container" style="text-align: left">
      <div class="row mb-2">
        <div class="col"></div>
        <div class="col"></div>
        <div class="col">
          <div class="btn-group m-3" style="width: 100%">
          <router-link tag="button" class="btn btn-outline-primary" :to="{ name: 'editProduct', params: { id: product.id }}">Edit</router-link>
          <button class="btn btn-outline-primary" @click="this.deletingProduct = true">Delete</button>
        </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <p><b>Title</b></p>
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(value, key, index) in this.product.title" :key="index">
              {{value}}
              <span class="badge bg-secondary rounded-pill">{{key.replace('_', ' - ')}}</span>
            </li>
          </ul>
        </div>
        <div class="col">
          <p><b>Text link</b></p>
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(value, key) in this.product.text_link" :key="key">
              {{value}}
              <span class="badge bg-secondary rounded-pill">{{key.replace('_', ' - ')}}</span>
            </li>
          </ul>
        </div>
        <div class="col">
          <p><b>Description</b></p>
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(value, key) in this.product.description" :key="key">
              {{value}}
              <span class="badge bg-secondary rounded-pill">{{key.replace('_', ' - ')}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <p><b>Brand</b> : {{this.product.brand}}</p>
        </div>
        <div class="col">
          <p><b>URL</b> : <a v-bind:href="this.product.url">{{this.product.url}}</a></p>
        </div>
        <div class="col">
          <p><b>Price</b> : {{this.product.price}}</p>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <p v-if="this.product.eco_friendly">This product is labeled as <b>eco-friendly</b></p>
          <p v-else>This product is <b>not labeled as eco-friendly</b></p>
        </div>
        <div class="col">
          <p><b>Start date</b> : {{this.product.start_date}}</p>
        </div>
        <div class="col">
          <p><b>End date</b> : {{this.product.end_date}}</p>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <div class="d-grid d-md-block mb-3">
            <p><b>Categories</b> :</p>
            <button class="btn btn-primary rounded-pill m-1" v-for="category in this.product.categories" :key="category" disabled>{{ this.findNameByID(category) }}</button>
          </div>
        </div>
        <div class="col">
          <p><b>Logo</b> :</p>
          <img :src="this.product.logo_url" alt="logo" style="width: 70%">
        </div>
        <div class="col">
          <p><b>Picture</b> :</p>
          <img :src="this.product.picture_url" alt="picture"  style="width: 70%">
        </div>
      </div>
    </div>
    
    <div v-if="this.transactions.length == 0">
      <p><b>Transactions</b>: No transactions for this product at this moment.</p>
    </div>
    <div v-else>
      <p><b>Transactions</b> (Total : {{ this.transactions.length }})</p>
      <table class="table table-striped table-hover table-bordered">
        <thead>
          <tr>
            <td>Date</td>
            <td>Owner</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transaction in this.transactions" :key="transaction.id">
            <td>{{ transaction.date }}</td>
            <td><router-link :to="{ name: 'userDetails', params: { id: transaction.owner }}">{{ transaction.user_full_name }}</router-link></td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <Modal v-if="deletingProduct" 
      v-on:sendFieldValue="deleteProduct"
      v-on:cancelModal="this.deletingProduct = false"
      :title="'Delete confirmation'"
      :message="'Are you sure you want to delete this product ?'"
      :error="''"
      :field="false"
    />
  </div>
</template>

<script>
import { db } from '@/firebase';
import Modal from '@/components/Modal.vue';

export default {
  name: 'ProductDetails',
  props: ["context"],
  components: { Modal },
  async created() {
    await this.getProductData();
    await this.getTransactions();
  },
  data() {
    return {
      product: [],
      transactions: [],
      options: [{name:'Accessories', id:'ACCESSORIES'},
            {name:'Auto-Moto', id:'AUTO_MOTO'},
            {name:'Well-Being', id:'BEAUTY_WELL_BEING'},
            {name:'Leisure', id:'LEISURE_CULTURE'},
            {name:'Kids', id:'KIDS'},
            {name:'Gardens & Flowers', id:'GARDEN_FLOWERS'},
            {name:'Home', id:'HOME'},
            {name:'Fashion', id:'FASHION'},
            {name:'Food', id:'FOOD'},
            {name:'Trips', id:'TRIP'}
        ],
        deletingProduct: false
    }
  },
  methods: {
    async getProductData(){
      const productRef = db.collection('contexts').doc(this.context).collection('products').doc(this.$route.params.id);
      await productRef.onSnapshot(docSnapshot => {
        this.product = docSnapshot.data();
        this.product.id = this.$route.params.id;
        this.product.start_date = docSnapshot.data().start_date.toDate().toDateString();
        this.product.end_date = docSnapshot.data().end_date.toDate().toDateString();
      });
    },
    async getTransactions(){
      const transactionsRef = db.collection('contexts').doc(this.context).collection('transactions').where("product", "==", this.$route.params.id);
      await transactionsRef.onSnapshot((docSnapshot) => {
        this.transactions = [];
        docSnapshot.forEach(element => {
          //Build transaction object
          let transaction = element.data();
          transaction.id = element.id;
          transaction.date = element.data().date.toDate().toString();

          //Get user
          const userRef = db.collection('contexts').doc(this.context).collection('users').doc(transaction.owner);
          userRef.get().then((doc) => {
            transaction.user_full_name = doc.data().first_name+' '+doc.data().last_name;
            this.transactions.push(transaction);
          });
        });
        
    });
    },
    findNameByID(id){
      for(const option of this.options){
        if (option.id == id){
          return option.name;
        }
      }
    },
    async deleteProduct(){
      const productRef = db.collection('contexts').doc(this.context).collection('products').doc(this.$route.params.id);
      await productRef.delete();
      await this.$router.push('/dashboard/store');
      alert("Product successfully deleted.");
    }
  }
}
</script>

<style>

</style>