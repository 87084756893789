<template>
  <div class="modal-background">
  <div class="modal-dialog" style="position: absolute; margin: auto; top: 30%; left: 0; bottom: 0; right: 0;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ this.title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="this.$emit('cancelModal')"></button>
        </div>
        <div class="modal-body">
          {{ this.message }}
          <br>
          <input v-if="this.field == true" ref="field" type="number" min="0">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="this.$emit('cancelModal')">Cancel</button>
          <button type="button" class="btn btn-primary" @click="this.$emit('sendFieldValue', value)">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "Modal",
    props: ["title", "message", "error", "field"],
    computed: {
      value() {
        if (this.field == true){
          return parseInt(this.$refs.field.value);
        }else{
          return null;
        }
      }
    }
}
</script>

<style>
.modal-background{
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
}
</style>