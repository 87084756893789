<template>
    <div>   
        <h2>Progress Curve</h2>
        <table class="table table-striped table-hover table-bordered">
            <thead>
                <tr>
                    <td>Level</td>
                    <td>XP</td>
                    <td>Cumulative XP</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(value, index) in this.curve" :key="index">
                    <td>{{index+1}}</td>
                    <td>
                        {{value}}
                        <button @click="this.modified = index" class="btn btn-primary" style="float: right">Edit</button>
                    </td>
                    <td>{{this.cumulativeSum(index)}}</td>
                </tr>
            </tbody>
        </table>

        <Modal v-if="this.modified != -1" 
            v-on:sendFieldValue="changeXP"
            v-on:cancelModal="this.modified = -1"
            :title="'Change XP for level '+parseInt(this.modified+1)"
            :message="'The number of XP will impact the next levels'"
            :error="''"
            :field="true"
        />
    </div>
</template>

<script>
import { db } from '@/firebase';
import Modal from '@/components/Modal.vue';

export default {
    name: 'ProgressCurve',
    props: ["context"],
    components: { Modal },
    data() {
        return {
            curve: [],
            modified: Number
        }
    },
    async created() {
        this.modified = -1;
        this.getProgressCurve(this.context);
    },
    methods: {
        async getProgressCurve(context) {
            const curveRef = db.collection('contexts').doc(context);
            await curveRef.onSnapshot(docSnapshot => {
                this.curve = docSnapshot.data().progress_curve;
            });
        },
        cumulativeSum(index){
            let sum = 1;
            for (let i = 0; i < index+1; i++){
                sum += this.curve[i];
            }
            return sum;
        },
        async changeXP(value){
            this.curve[this.modified] = value;
            await db.collection('contexts').doc(this.context).update({ progress_curve: this.curve });
            this.modified = -1;
        }
    }
}
</script>

<style>

</style>