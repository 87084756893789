<template>
  <div class="container m-3 p-3 mx-auto border rounded bg-light">
        <img src="../assets/logo.webp" style="width: 15%;">
        <h2>Himalaya Dashboard</h2>
        <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">E-mail</span>
            <input class="form-control" type="email" placeholder="Email" v-model="email" />
        </div>
        <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">Password</span>
            <input class="form-control" type="password" placeholder="Password" v-model="password" />
        </div>
        <button v-if="this.state=='USER_WAIT'" @click="login" class="btn btn-success">Login</button>
        <div v-if="this.state=='LOADING'" class="spinner-border text-success"></div>
  </div>
</template>

<script>
import firebase from 'firebase';

export default {
    name: "Auth",
    data() {
        return {
            email: "",
            password: "",
            state: 'USER_WAIT'
        }
    },
    methods: {
        async login () {
            this.state = 'LOADING';
            //Log the user in
            const signin = await firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(
                user => {
                    console.log(user.uid);
                },
                err => {
                    alert(err.message);
                    this.state = 'USER_WAIT'
                }
            );

            console.log(signin);
            const checkPrivileges = await firebase.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
                if (idTokenResult.claims.admin) {
                    console.log("Admin check passed successfully.");
                    this.$router.push('/dashboard/home');
                } else {
                    alert("This user is not an admin.");
                }
            })
            .catch((error) => {
                console.log(error);
            });
            console.log(checkPrivileges);
        }
    }
}
</script>

<style>

</style>