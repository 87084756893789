<template>
    <div>
        <table class="table table-striped table-hover table-bordered">
            <thead>
                <tr>
                    <th scope="col">Ride ID</th>
                    <th scope="col">Start Time</th>
                    <th scope="col">Status</th>
                    <th scope="col">Score</th>
                    <th scope="col">XP</th>
                    <th scope="col">eCoins</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="ride in this.rides" :key="ride.id">
                    <th scope="row">{{ ride.id }}</th>
                    <td>{{ ride.start_date.toDate().toString() }}</td>
                    <td>{{ ride.status }}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
            </tbody>
        </table>

        <nav>
            <ul class="pagination justify-content-center">
                <li class="page-item">
                <a class="page-link"  v-if="this.cursor > 1" aria-label="Previous" @click="goToPrevious()">
                    <span aria-hidden="true">&laquo;</span>
                </a>
                </li>
                <li class="page-item" v-if="this.cursor > 1" @click="goToPrevious()"><a class="page-link">{{this.cursor-1}}</a></li>
                <li class="page-item active"><a class="page-link">{{this.cursor}}</a></li>
                <li class="page-item" v-if="this.cursor < this.max_cursor-1"><a class="page-link" @click="goToNext()">{{this.cursor+1}}</a></li>
                <li class="page-item" v-if="this.cursor < this.max_cursor-1">
                <a class="page-link" aria-label="Next" @click="goToNext()">
                    <span aria-hidden="true">&raquo;</span>
                </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { db } from '@/firebase';

export default {
    name: 'UserRides',
    props: ["context", "uid"],
    data() {
        return {
            rides: [],
            perPage: 5,
            cursor: 1,
            requests: [],
            max_cursor: 1,
        }
    },
    async created() {
        //Get rides count
        await this.getMaxCursor();
        //Retrieve users list from Firestore
        this.requests.push(db.collection('contexts').doc(this.context).collection('users').doc(this.uid).collection('rides').orderBy("start_date", "desc"));
        this.getRides(this.requests[this.cursor-1], 'UP');
    },
    methods: {
        async getRides(req, direction) {
            await req.limit(this.perPage).get().then(docSnapshot => {
                console.log('Rides list refreshed.');
                this.rides = [];
                docSnapshot.forEach(doc => {
                    const ride = doc.data();
                    ride.id = doc.id;
                    this.rides.push(ride);
                });

                //Build next query
                var lastVisible = docSnapshot.docs[docSnapshot.docs.length-1];
                if (direction == 'UP'){
                    this.requests.push(db.collection('contexts').doc(this.context).collection('users').doc(this.uid).collection('rides').orderBy("start_date", "desc").startAfter(lastVisible).limit(this.perPage));
                }

            }, err => {
            console.log(`Encountered error: ${err}`);
            });
        },
        goToNext(){
            this.cursor++;
            this.getRides(this.requests[this.cursor-1], 'UP');
        },
        goToPrevious(){
            this.cursor--;
            this.getRides(this.requests[this.cursor-1], 'DOWN');
        },
        async getMaxCursor(){
            const snapshot = await db.collection('contexts').doc(this.context).collection('users').doc(this.uid).collection('rides').get();
            const rides_count = snapshot.size;
            this.max_cursor = (rides_count/this.perPage)+1;
            console.log('Max cursor : '+this.max_cursor);
        }
    }
}
</script>

<style>

</style>