<template>
    <div>
        <ul class="nav nav-pills">
            <li class="nav-item m-2" v-for="locale in selectedLocales" :key="locale">
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-outline-primary" v-bind:class="{active: this.activeLocale == locale}" v-on:click="this.editLocale(locale)">
                        {{ getNameFromID(locale) }}
                    </button>
                    <button class="btn btn-outline-primary" v-bind:class="{'disabled': selectedLocales.length == 1}" v-on:click="this.removeLocale(locale)">
                        <span class="pr-0">&times;</span>
                    </button>
                </div>
            </li>
            <li class="nav-item dropdown m-2" v-if="localesLeft.length > 0">
                <a class="dropdown-toggle btn btn-outline-primary" role="button" data-bs-toggle="dropdown">Add translation</a>
                <ul class="dropdown-menu">
                    <li v-for="locale in localesLeft" :key="locale"><a class="dropdown-item" v-on:click="this.addLocale(locale)">{{ getNameFromID(locale) }}</a></li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import { db } from '@/firebase';

export default {
    name: 'LocaleSelector',
    props: ["context"],
    data() {
        return {
            availableLocales: [],
            selectedLocales: [],
            default: '',
            activeLocale: ''
        }
    },
    async created() {
        await this.getAvailableLocales();
    },
    computed: {
        localesLeft() {
            var locales = [];
            for (const locale of this.availableLocales){
                if (!this.selectedLocales.includes(locale.id)){
                    locales.push(locale.id);
                }
            }
            return locales;
        }
    },
    methods: {
        async getAvailableLocales() {
            db.collection('contexts').doc(this.context).get().then((doc) => {
                this.default = doc.data().default_locale;
                this.activeLocale = this.default;
                this.selectedLocales.push(this.default);
                const locales = doc.data().locales;
                for (const [key, value] of Object.entries(locales)){
                    var locale = {};
                    locale.id = key;
                    locale.name = value;
                    this.availableLocales.push(locale);
                }
                this.sendLocalesToParent();
            });
        },
        getNameFromID(id){
            for (const locale of this.availableLocales){
                if (locale.id == id){
                    return locale.name;
                }
            }
            return 'unknown';
        },
        addLocale(id){
            this.selectedLocales.push(id);
            this.sendLocalesToParent();
        },
        removeLocale(id){
            for (let i = 0; i< this.selectedLocales.length; i++){
                if (this.selectedLocales[i] == id){
                    this.selectedLocales.splice(i, 1);
                    i--;
                }
            }
            this.activeLocale = this.selectedLocales[0];
            console.log('New active locale : '+this.activeLocale);
            this.sendLocalesToParent();
        },
        sendLocalesToParent(){
            let localesBundle = {};
            localesBundle.selectedLocales = this.selectedLocales;
            localesBundle.activeLocale = this.activeLocale;
            this.$emit('sendLocales', localesBundle);
        },
        editLocale(id){
            this.activeLocale = id;
            console.log('New active locale : '+this.activeLocale);
            this.sendLocalesToParent();
        }
    }
}
</script>

<style>

</style>