<template>
  <div>
    <h2>404 : Page not found</h2>
    <button @click="this.$router.push('/login');">Go back to login page</button>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>