<template>
    <div class="btn-group-vertical  btn-group-lg">
        <router-link tag="button" class="btn btn-outline-primary btn-block" to="/dashboard/home">Home</router-link>
        <router-link tag="button" class="btn btn-outline-primary btn-block" to="/dashboard/users">Users</router-link>
        <router-link tag="button" class="btn btn-outline-primary btn-block" to="/dashboard/store">Store</router-link>
        <router-link tag="button" class="btn btn-outline-primary btn-block" to="/dashboard/challenges">Challenges</router-link>
    </div>
</template>

<script>
export default {
    name: 'Sidebar'
}
</script>

<style>

</style>