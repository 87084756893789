<template>
    <div class="container">
        <h2>Create product</h2>
        <form style="text-align: left;">
            <div class="row g-3">
                <div class="col mb-3">
                    <locale-selector 
                    :context="this.context"
                    v-on:sendLocales="getLocales"></locale-selector>
                </div>
            </div>

            <div class="row g-3">
                <div class="col mb-3">
                    <label for="title" class="form-label">Title</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="product.title[this.activeLocale]" name="title">
                        <span class="input-group-text">{{ this.shortActiveLocale }}</span>
                    </div>
                </div>
                <div class="col mb-3">
                    <label for="brand" class="form-label">Brand</label>
                    <input type="text" class="form-control" v-model="product.brand" name="brand" max="15">
                </div>
            </div>

            <div class="row g-3">
                <div class="col mb-3">
                    <div class="col mb-3">
                        <label for="text_link" class="form-label">Text Link</label>
                        <div class="input-group">
                            <input type="text" class="form-control" name="text_link" id="text_link" v-model="product.text_link[this.activeLocale]">
                            <span class="input-group-text">{{ this.shortActiveLocale }}</span>
                        </div>
                    </div>
                    
                    <div class="form-check mb-3">
                        <input type="checkbox" class="form-check-input" name="eco_friendly" id="eco_friendly" v-model="product.eco_friendly">
                        <label for="eco_friendly" class="form-check-label">Eco-Friendly</label>
                    </div>

                    <div class="form-check mb-3">
                        <input type="checkbox" class="form-check-input" name="sponsored" id="sponsored" v-model="product.sponsored">
                        <label for="sponsored" class="form-check-label">Sponsored</label>
                    </div>
                </div>
                <div class="col mb-3">
                    <label for="description" class="form-label">Description</label>
                    <div class="input-group">
                        <textarea class="form-control" rows="4" cols="60" style="resize: none;" v-model="product.description[this.activeLocale]" name="description"></textarea>
                        <span class="input-group-text">{{ this.shortActiveLocale }}</span>
                    </div>
                </div>
            </div>

            <div class="row g-3">
                <div class="col mb-3">
                    <label for="price" class="form-label">Price</label>
                    <input type="number" class="form-control" name="price" id="price" min="0" v-model="product.price">
                </div>
                <div class="col mb-3">
                    <label for="url" class="form-label">URL</label>
                    <input type="text" class="form-control" name="url" id="url" v-model="product.url">
                </div>
            </div>

            <div class="row g-3">
                <div class="col mb-3">
                    <div class="row g-3 mb-4">
                        <div class="col mb-3">
                            <label for="start_date" class="form-label">Start date</label>
                            <input type="date" class="form-control" name="start_date" id="start_date" v-model="product.start_date" style="width: 70%">
                        </div>
                        <div class="col mb-3">
                            <label for="end_date" class="form-label">End date</label>
                            <input type="date" class="form-control" name="end_date" id="end_date" v-model="product.end_date" style="width: 70%">
                        </div>
                    </div>
                    <div class="row g-3">
                        <label for="categories" class="form-label" style="text-align: center;">Available categories</label>
                        <div name="categories" class="d-grid d-md-block">
                            <button v-for="option in this.options" :key="option.id" @click="addOption(option.id)" class="btn btn-outline-primary rounded-pill m-1">{{ option.name }}</button>
                        </div>
                        <label for="categories" class="form-label" style="text-align: center;">Selected categories</label>
                        <div class="d-grid d-md-block mb-3">
                            <p v-if="this.product.categories.length == 0">No categories selected.</p>
                            <button v-for="option in this.product.categories" :key="option.id" @click="removeOption(option.id)" class="btn btn-primary rounded-pill m-1">{{ option.name }}</button>
                        </div>
                    </div>
                    <div class="row g-3 mt-2">
                        <div v-if="this.product.voucher.type == 'GENERIC'" class="row">
                            <div class="col">
                                <label for="voucher" class="form-label"><b>Generic</b> voucher</label>
                                <button class="btn btn-outline-primary" v-on:click="this.product.voucher.type='SINGLE-USE'">Set single-use vouchers instead</button>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" v-model="product.voucher.value" name="voucher" id="voucher">
                            </div>
                        </div>
                        <div v-else class="row">
                            <div class="col">
                                <label for="voucher" class="form-label"><b>Single-use</b> voucher list</label>
                                <button class="btn btn-outline-primary" v-on:click="this.product.voucher.type='GENERIC'">Set a generic voucher instead</button>
                            </div>
                            <div class="col">
                                <div class="form-floating">
                                    <textarea class="form-control" id="floatingTextarea" row="10" cols="10" style="resize: none;" v-model="product.voucher.value" name="voucher"></textarea>
                                    <label for="floatingTextarea">One code per line</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col mb-3">
                    <div class="mb-3">
                        <label for="logo" class="form-label">Logo</label>
                        <input type="file" class="form-control" name="logo" id="logo" @change="onLogoSelected">
                    </div>
                    <div class="mb-3">
                        <label for="pic" class="form-label">Picture</label>
                        <input type="file" class="form-control" name="pic" id="pic" @change="onPictureSelected">
                    </div>
                    <div class="row g-3">
                        <div class="col mb-3" style="text-align: center;">
                            <label class="form-label">Logo preview</label><br>
                            <img :src="logoURL" style="width: 70%;">
                        </div>
                        <div class="col mb-3" style="text-align: center;">
                            <label class="form-label">Picture preview</label><br>
                            <img :src="picURL" style="width: 70%;">
                        </div>
                    </div>
                </div>
            </div>

        </form>
        <div v-if="this.errors.length != 0" div class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Please fix the following errors before creating a new product</h4>
            <hr>
            <p v-for="error in this.errors" :key="error">{{ error }}</p>
        </div>
        <button v-if="!this.submitted" class="btn btn-primary" v-on:click="this.createProduct()">Create Product</button>
        <div v-else class="spinner-border text-success"></div>
    </div>
</template>

<script>
import LocaleSelector from '@/components/LocaleSelector.vue';
import { storage } from '@/firebase';
import { db } from '../../../firebase';

export default {
    name: 'CreateProduct',
    props: ["context"],
    components: {LocaleSelector},
    data() {
        return {
            locales: [],
            activeLocale: '',
            submitted: false,
            errors: [],
            logoURL: "",
            picURL: "",
            selectedLogo: null,
            selectedPicture: null,
            options: [{name:'Accessories', id:'ACCESSORIES'},
                {name:'Auto-Moto', id:'AUTO_MOTO'},
                {name:'Well-Being', id:'BEAUTY_WELL_BEING'},
                {name:'Leisure', id:'LEISURE_CULTURE'},
                {name:'Kids', id:'KIDS'},
                {name:'Gardens & Flowers', id:'GARDEN_FLOWERS'},
                {name:'Home', id:'HOME'},
                {name:'Fashion', id:'FASHION'},
                {name:'Food', id:'FOOD'},
                {name:'Trips', id:'TRIP'}
            ],
            product: {
                title: {},
                brand: "",
                price: 0,
                description: {},
                logo_url: "",
                picture_url: "",
                text_link: {},
                url: "",
                start_date: "",
                end_date: "",
                eco_friendly: false,
                sponsored: false,
                categories: [],
                voucher: {
                    type: 'GENERIC',
                    value: ''
                }
            }
            

        }
    },
    computed: {
        shortActiveLocale() {
            return this.activeLocale.replace('_', ' - ');
        }
    },
    methods: {
        onLogoSelected(event){
            this.selectedLogo = event.target.files[0];
            this.logoURL= URL.createObjectURL(this.selectedLogo);
        },
        onPictureSelected(event){
            this.selectedPicture = event.target.files[0];
            this.picURL= URL.createObjectURL(this.selectedPicture);
        },
        async createProduct(){
            this.submitted = true;
            //Control fields integrity
            this.checkProduct();
            if (this.errors.length != 0){
                this.submitted = false;
                return;
            }
            
            //Set Firebase refs
            const imageRef = storage.ref().child(this.context+'/products');
            const dbRef = db.collection('contexts').doc(this.context).collection('products');

            //Build file names
            const fireStoreID = (this.product.brand+'-'+this.makeid(19)).slice(0,20).replace(' ', '_');
            const logoName = fireStoreID+'_logo.'+this.selectedLogo.name.substring(this.selectedLogo.name.lastIndexOf('.') + 1);
            const pictureName = fireStoreID+'_pic.'+this.selectedPicture.name.substring(this.selectedPicture.name.lastIndexOf('.') + 1);
            console.log('ID : '+fireStoreID);
            console.log('Logo : '+logoName);
            console.log('Picture : '+pictureName);

            //Upload images
            var logoRef = imageRef.child('logos/'+logoName);
            await logoRef.put(this.selectedLogo).then((snapshot) => {
                console.log('Uploaded logo !');
                console.log(snapshot);
            });
            this.product.logo_url = await logoRef.getDownloadURL();

            var picRef = imageRef.child('pictures/'+pictureName);
            await picRef.put(this.selectedPicture).then((snapshot) => {
                console.log('Uploaded Picture !');
                console.log(snapshot);
            });
            this.product.picture_url = await picRef.getDownloadURL();

            //Handle voucher
            let vouchersArray = [];
            if (this.product.voucher.type == 'SINGLE-USE'){
                const vouchersText = this.product.voucher.value;
                console.log(vouchersText);
                vouchersArray = vouchersText.split('\n');
                console.log(vouchersArray);
                delete this.product.voucher.value;
            }

            //Handle price
            this.product.price = parseInt(this.product.price);

            //Handle categories
            const objCat = this.product.categories;
            this.product.categories = [];
            objCat.forEach(cat => {
                this.product.categories.push(cat.id);
            });

            //Handle dates
            const start_date = this.product.start_date;
            this.product.start_date = new Date(start_date);
            const end_date = this.product.end_date;
            this.product.end_date = new Date(end_date);
            await dbRef.doc(fireStoreID).set(this.product);
            console.log(this.product);
            if (this.product.voucher.type == 'SINGLE-USE'){
                const vouchersRef = dbRef.doc(fireStoreID).collection('vouchers');
                for (const voucher of vouchersArray){
                    await vouchersRef.doc(voucher).set({status: 'AVAILABLE'});
                }
            }
            await this.$router.push('/dashboard/store');
            alert("Product successfully created !");
            this.submitted = false;
            

        },
        checkProduct() {
            this.errors = [];
            const textFields = ['brand', 'url'];
            for (const textField of textFields){
                if (this.product[textField] == ''){
                    this.errors.push("Field '"+textField+"' cannot be empty.");
                }
            }

            const localeTextFields = ['title', 'description', 'text_link'];
            for (const textField of localeTextFields){
                if (Object.keys(this.product[textField]).length != this.locales.length){
                    this.errors.push("Missing translations for "+textField);
                }
            }

            if (this.product.price < 0){
                    this.errors.push("Price cannot be a negative value.");
            }
            if (this.product.categories.length == 0){
                this.errors.push("Please select at least one category.");
            }

            const supportedExt = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG'];
            if (this.logoURL == ""){
                this.errors.push("You must upload a product logo.");
            }else{
                const logoExt = this.selectedLogo.name.substring(this.selectedLogo.name.lastIndexOf('.') + 1);
                if (!supportedExt.includes(logoExt)){
                    this.errors.push("Logo image type unsupported.");
                }
            }
            if (this.picURL == ""){
                this.errors.push("You must upload a product picture.");
            }else{
                const picExt = this.selectedPicture.name.substring(this.selectedPicture.name.lastIndexOf('.') + 1);
                if (!supportedExt.includes(picExt)){
                    this.errors.push("Picture image type unsupported.");
                }
            }
            if  (this.product.start_date == ""){
                this.errors.push("Please enter a valid start date.");
            }
            if  (this.product.end_date == ""){
                this.errors.push("Please enter a valid end date.");
            }
            if  (this.product.start_date >= this.product.end_date){
                this.errors.push("End date must be after start date.");
            }
            if (this.product.voucher.value == ''){
                this.errors.push("Please enter a valid voucher.");
            }
        },
        makeid(length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        addOption(id){
            let i = 0;
            this.options.forEach(option => {
                if (option.id == id){
                    this.product.categories.push(option);
                    this.options.splice(i, 1);
                }
                i++;
            });
        },
        removeOption(id){
            let i = 0;
            this.product.categories.forEach(option => {
                if (option.id == id){
                    this.options.push(option);
                    this.product.categories.splice(i, 1);
                }
                i++;
            });
        },
        findNameByID(id){
            for(const option of this.options){
                if (option.id == id){
                    return option.name;
                }
            }
        },
        getLocales(value){
            const localesBundle = value;
            this.locales = localesBundle.selectedLocales;
            this.activeLocale = localesBundle.activeLocale;
        }
    }
}
</script>

<style>

</style>