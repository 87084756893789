<template>
    <div>
        <h4>Challenge Details</h4>
        <div class="container" style="text-align: left;">
            <div class="row mb-2">
                <div class="col">
                    <b>Title</b>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(value, key, index) in this.challenge.title" :key="index">
                        {{value}}
                        <span class="badge bg-secondary rounded-pill">{{key.replace('_', ' - ')}}</span>
                        </li>
                    </ul>
                </div>
                <div class="col" v-if="this.challenge.unlock_type == 'CODE'">
                    <b>Code</b> : {{ challenge.code }}
                </div>
                <div class="col" v-else-if="this.challenge.unlock_type == 'ECOINS'">
                    <b>Price</b> : {{ challenge.price }}
                </div>
                <div class="col" v-else>
                    <b>Free Access</b>
                </div>
                <div class="col">
                    <b>Description</b>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(value, key) in this.challenge.description" :key="key">
                        {{value}}
                        <span class="badge bg-secondary rounded-pill">{{key.replace('_', ' - ')}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-8">
                    <div class="row mb-2">
                        <div class="col">
                            <b>Start date</b> : {{ challenge.start_date }}
                        </div>
                        <div class="col">
                            <b>End date</b> : {{ challenge.end_date }}
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <b>Parter URL</b> : <a v-bind:href="challenge.partner_url">{{ challenge.partner_url }}</a>
                        </div>
                        <div class="col">
                            <b>Rules URL</b> : <a v-bind:href="challenge.rules_url">{{ challenge.rules_url }}</a>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <b>Color</b> : {{ challenge.color }}
                        </div>
                        <div class="col">
                            <b>Icon</b> : {{ challenge.icon }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <b>Logo</b> :<br>
                    <img :src="this.challenge.logo_url" alt="logo" style="width: 70%">
                </div>
            </div>
        </div>
        <ul class="nav nav-tabs mt-4">
            <li class="nav-item">
                <a v-if="this.tabComponent!='ChallengeParticipations'" class="nav-link" @click="this.switchTab('ChallengeParticipations')">Participations</a>
                <a v-else class="nav-link active">Participations</a>
            </li>
            <li class="nav-item">
                <a v-if="this.tabComponent!='ChallengeRewards'" class="nav-link" @click="this.switchTab('ChallengeRewards')">Rewards</a>
                <a v-else class="nav-link active">Rewards</a>
            </li>
            <li class="nav-item">
                <a v-if="this.tabComponent!='ChallengeConditions'" class="nav-link" @click="this.switchTab('ChallengeConditions')">Conditions</a>
                <a v-else class="nav-link active">Conditions</a>
            </li>
        </ul>
        <component
            :is="this.tabComponent"
            :context="this.context"
            :id="this.$route.params.id">
        </component>
    </div>
</template>

<script>
import { db } from '@/firebase';
import ChallengeParticipations from '@/components/ChallengeParticipations.vue';
import ChallengeRewards from '@/components/ChallengeRewards.vue';
import ChallengeConditions from '@/components/ChallengeConditions.vue';
import { shallowRef } from 'vue';

export default {
    name: 'challengeDetails',
    components: { ChallengeParticipations, ChallengeRewards, ChallengeConditions },
    props: ["context"],
    async created() {
        this.getChallengeData();
    },
    data() {
        return {
            challenge: [],
            tabComponent: shallowRef('ChallengeParticipations')
        }
    },
    methods: {
        async getChallengeData(){
            const challengeRef = db.collection('contexts').doc(this.context).collection('challenges').doc(this.$route.params.id);
            await challengeRef.get().then(doc => {
                this.challenge = doc.data();
                this.challenge.start_date = doc.data().start_date.toDate().toDateString();
                this.challenge.end_date = doc.data().end_date.toDate().toDateString();
                this.challenge.id = doc.id;
            });
        },
        switchTab(component){
            this.tabComponent=shallowRef(component);
        }
    }
}
</script>

<style>

</style>