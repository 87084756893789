import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Auth from '../views/Auth.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Store from '../views/dashboard/Store.vue'
import Users from '../views/dashboard/Users.vue'
import Challenges from '../views/dashboard/Challenges.vue'
import Default from '../views/dashboard/Default.vue'

import UserDetails from '../views/dashboard/users/UserDetails.vue'
import ProgressCurve from '../views/dashboard/users/ProgressCurve.vue'
import ProductDetails from '../views/dashboard/products/ProductDetails.vue'
import CreateProduct from '../views/dashboard/products/CreateProduct.vue'
import EditProduct from '../views/dashboard/products/EditProduct.vue'
import ChallengeDetails from '../views/dashboard/challenges/ChallengeDetails.vue'
import CreateChallenge from '../views/dashboard/challenges/CreateChallenge.vue'

const routes = [
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: PageNotFound,
    meta: {
      requiresAuth: false
    }
  },
  { path: '/', redirect: '/login' },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      {
        path: 'challenges',
        name: 'challenges',
        component: Challenges,
      },
      {
        path: 'challenges/:id',
        name: 'challengeDetails',
        component: ChallengeDetails,
      },
      {
        path: 'challenges/new',
        name: 'createChallenge',
        component: CreateChallenge,
      },
      {
        path: 'users',
        name: 'users',
        component: Users,
      },
      {
        path: 'users/:id',
        name: 'userDetails',
        component: UserDetails
      },
      {
        path: 'progress_curve',
        name: 'progressCurve',
        component: ProgressCurve
      },
      {
        path: 'store',
        name: 'store',
        component: Store
      },
      {
        path: 'store/:id',
        name: 'productDetails',
        component: ProductDetails
      },
      {
        path: 'store/:id/edit',
        name: 'editProduct',
        component: EditProduct
      },
      {
        path: 'store/new',
        name: 'createProduct',
        component: CreateProduct
      },
      {
        path: 'home',
        name: 'home',
        component: Default
      }
    ]
  },
  {
    path: '/login',
    name: 'Auth',
    component: Auth
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
