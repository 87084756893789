<template>
    <h3>Welcome !</h3>
</template>

<script>
export default {
    name: 'Default',
    props: ["context"]
}
</script>

<style>

</style>