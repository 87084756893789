<template>
    <div>
        <h2>Users</h2>
        <div class="mb-3">
            <div class="row">
                <div class="col">
                    <input type="text" class="form-control" placeholder="e-mail" v-model="searchMail">
                </div>
                <div class="col">
                    <button class="btn btn-primary" type="button" @click="searchRequest" style="float: left;">Search</button>
                </div>
                <div class="col">
                    <!--<router-link to="progress_curve" tag="button" class="btn btn-primary" style="float: right;">Progress Curve</router-link>-->
                </div>
            </div>
        </div>
        <table class="table table-striped table-hover table-bordered">
            <thead>
                <tr>
                    <th scope="col">e-mail</th>
                    <th scope="col">Full Name</th>
                    <th scope="col">level</th>
                    <th scope="col">XP</th>
                    <th scope="col">e-coins</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in this.users" :key="user.uid"  @click="goToUser(user.uid)" href="#">
                    <td>{{ user.email }}</td>
                    <th scope="row">{{ user.first_name }} {{ user.last_name }}</th>
                    <td>{{ user.level }}</td>
                    <td>{{ user.xp }}</td>
                    <td>{{ user.ecoins }}</td>
                </tr>
            </tbody>
        </table>

        <nav>
            <ul class="pagination justify-content-center">
                <li class="page-item">
                <a class="page-link"  v-if="this.cursor > 1" aria-label="Previous" @click="goToPrevious()">
                    <span aria-hidden="true">&laquo;</span>
                </a>
                </li>
                <li class="page-item" v-if="this.cursor > 1" @click="goToPrevious()"><a class="page-link">{{this.cursor-1}}</a></li>
                <li class="page-item active"><a class="page-link">{{this.cursor}}</a></li>
                <li class="page-item" v-if="this.cursor < this.max_cursor-1"><a class="page-link" @click="goToNext()">{{this.cursor+1}}</a></li>
                <li class="page-item" v-if="this.cursor < this.max_cursor-1">
                <a class="page-link" aria-label="Next" @click="goToNext()">
                    <span aria-hidden="true">&raquo;</span>
                </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { db } from '@/firebase';

export default {
    name: 'Users',
    props: ["context"],
    async created() {
        this.baseRequest = db.collection('contexts').doc(this.context).collection('users').orderBy("xp", "desc");
        //Get users count
        await this.getMaxCursor();
        //Retrieve users list from Firestore
        this.requests.push(this.baseRequest);
        this.getUsers(this.requests[this.cursor-1], 'UP');
    },
    data() {
        return {
            baseRequest: null,
            users: [],
            perPage: 10,
            cursor: 1,
            requests: [],
            max_cursor: 1,
            searchMail: ''
        }
    },
    methods: {
        async getUsers(req, direction) {
            await req.limit(this.perPage).get().then(docSnapshot => {
                console.log('Users list refreshed.');
                this.users = [];
                docSnapshot.forEach(doc => {
                    const user = doc.data();
                    user.uid = doc.id;
                    this.users.push(user);
                });

                //Build next query
                var lastVisible = docSnapshot.docs[docSnapshot.docs.length-1];
                if (direction == 'UP'){
                    this.requests.push(this.baseRequest.startAfter(lastVisible).limit(this.perPage));
                }

            }, err => {
            console.log(`Encountered error: ${err}`);
            });
        },
        goToUser(uid){
            this.$router.push({ name: 'userDetails', params: { id: uid } });
        },
        goToNext(){
            this.cursor++;
            this.getUsers(this.requests[this.cursor-1], 'UP');
        },
        goToPrevious(){
            this.cursor--;
            this.getUsers(this.requests[this.cursor-1], 'DOWN');
        },
        async getMaxCursor(){
            const snapshot = await this.baseRequest.get();
            const users_count = snapshot.size;
            this.max_cursor = (users_count/this.perPage)+1;
            console.log('Max cursor : '+this.max_cursor);
        },
        async searchRequest(){
            this.baseRequest = db.collection('contexts').doc(this.context).collection('users').where('email', '>=', this.searchMail).where('email', '<=', this.searchMail+ '\uf8ff');
            this.requests = [];
            this.requests.push(this.baseRequest);
            //Get users count
            await this.getMaxCursor();
            this.getUsers(this.requests[this.cursor-1], 'UP');
}
    }
}
</script>

<style>
tr {
    cursor: pointer;
}
</style>