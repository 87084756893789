<template>
    <div>
        <table class="table table-sm table-bordered">
            <thead>
                <tr>
                    <th scope="col" style="width: 50%">Variable</th>
                    <th scope="col" style="width: 50%">Value</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="condition in this.conditions" :key="condition.id">
                    <td>{{ condition.name }}</td>
                    <td>
                        <div class="input-group">
                            <input type="number" class="form-control" :placeholder="condition.name" v-model="condition.value" :readonly="this.edited != condition.id" :disabled="this.waiting == condition.id">
                            <button v-if="this.edited != condition.id" class="btn btn-outline-primary" type="button" @click="this.edited = condition.id" :disabled="this.waiting == condition.id">Edit</button>
                            <button v-else class="btn btn-outline-primary" type="button" @click="this.editCondition(condition.id, condition.value)" :disabled="this.waiting == condition.id">Apply</button>
                            <button class="btn btn-outline-danger" type="button" @click="this.deleteCondition(condition.id, condition.value)" :disabled="this.waiting == condition.id">Delete</button>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="this.optionsLeft.length > 0">
                <tr>
                    <td>
                        <select name="options_left" v-model="new_type">
                            <option value="">- Add a new variable -</option>
                            <option v-for="option in this.optionsLeft" :key="option.id" :value="option.id">{{option.name}}</option>
                        </select>
                    </td>
                    <td>
                        <div class="input-group" v-if="new_type != ''">
                            <input type="number" class="form-control" name="value" id="value" placeholder="Please enter a value" v-model="new_value" min="1">
                            <button class="btn btn-outline-primary" type="button" id="button-addon2" @click="this.uploadCondition()">Confirm</button>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import { db } from '@/firebase';

export default {
    name: 'ChallengeRewards',
    props: ["context", "id"],
    data() {
        return {
            waiting: null,
            edited: null,
            baseRequest: null,
            conditions: [],
            options: [{name:'Min. Distance per trip (km)', id:'MIN_DISTANCE_PER_TRIP'},
            {name:'Min. Total Distance (km)', id:'MIN_DISTANCE_TOTAL'},
                {name:'Min. number of trips', id:'MIN_TRAJECTS'},
                {name:'Min. Score', id:'SCORE'},
                {name:'Total Duration (minutes)', id:'DURATION_TOTAL'},
                {name:'Duration per trip (minutes)', id:'DURATION_PER_TRIP'}
            ],
            new_type: "",
            new_value: 0
        }
    },
    computed: {
        optionsLeft() {
            let optionsMap = [];
            let conditionsID = [];
            this.conditions.forEach(c => {
                conditionsID.push(c.type);
            });
            for (const option of this.options) {
                if (!conditionsID.includes(option.id)){
                    optionsMap.push(option);
                }
            }
            return optionsMap;
        }
    },
    async created() {
        this.baseRequest = db.collection('contexts').doc(this.context).collection('challenges').doc(this.id).collection('conditions');
        await this.getConditions();
    },
    methods: {
        async getConditions(){
            await this.baseRequest.get().then(docSnapshot => {
                this.conditions = [];
                docSnapshot.forEach(doc => {
                    const condition = doc.data();
                    condition.id = doc.id;
                    condition.name = this.getNameFromID(condition.type);
                    this.conditions.push(condition);
                });
            });
        },
        getNameFromID(id){
            for (const option of this.options) {
                if (option.id == id){
                    return option.name;
                }
            }
            return 'Unknown type'
        },
        async uploadCondition(){
            if (this.new_value <= 0){
                alert("Value must be > 0");
                return;
            }
            await this.baseRequest.doc(this.new_type).set({
                type: this.new_type,
                value: parseInt(this.new_value)
            });
            this.new_type = "";
            this.new_value = 0;
            await this.getConditions();
        },
        async deleteCondition(id){
            await this.baseRequest.doc(id).delete();
            await this.getConditions();
        },
        async editCondition(id, value){
            this.edited = null;
            this.waiting = id;
            await this.baseRequest.doc(id).update({ value: parseInt(value) });
            await this.getConditions();
            this.waiting = null;
        }
    }
}
</script>

<style>

</style>