<template>
    <div>
        <table class="table table-sm table-bordered">
            <thead>
                <tr>
                    <th scope="col">Rewards</th>
                    <th scope="col" style="width: 15%">From position</th>
                    <th scope="col" style="width: 15%">To position</th>
                    <th scope="col" style="width: 15%"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="rewardPack in this.rewards" :key="rewardPack.id">
                    <td>
                        <table class="table table-striped table-sm">
                            <thead>
                                <th scope="col" style="width: 25%">Type</th>
                                <th scope="col">Value</th>
                            </thead>
                            <tbody v-if='this.state == "RANGE_EDIT" && this.edited_range == rewardPack'>
                                <tr>
                                    <td>XP</td>
                                    <td>
                                        <div class="input-group">
                                            <input type="number" class="form-control" name="value" id="value" placeholder="No XP" v-model="rewardPack.xp">
                                            <button class="btn btn-outline-danger" type="button" id="button-addon2" @click="rewardPack.xp = null">Clear</button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>e-Coins</td>
                                    <td>
                                        <div class="input-group">
                                            <input type="number" class="form-control" name="value" id="value" placeholder="No e-Coins" v-model="rewardPack.ecoins">
                                            <button class="btn btn-outline-danger" type="button" id="button-addon2" @click="rewardPack.ecoins = null">Clear</button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Custom Reward</td>
                                    <td>
                                        <div class="input-group">
                                            <input type="text" class="form-control" name="value" id="value" placeholder="No custom reward" v-model="rewardPack.custom">
                                            <button class="btn btn-outline-danger" type="button" id="button-addon2" @click="rewardPack.custom = null">Clear</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-if="rewardPack.xp != null">
                                    <td>XP</td>
                                    <td>{{ rewardPack.xp }}</td>
                                </tr>
                                <tr v-if="rewardPack.ecoins != null">
                                    <td>e-Coins</td>
                                    <td>{{ rewardPack.ecoins }}</td>
                                </tr>
                                <tr v-if="rewardPack.custom != null">
                                    <td>Custom reward</td>
                                    <td>{{ rewardPack.custom }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td v-if='this.state == "RANGE_EDIT" && this.edited_range == rewardPack'>
                        <input type="number" name="min_position" id="min_position" v-model="rewardPack.min_position" min="1">
                    </td>
                    <td v-else>{{ rewardPack.min_position }}</td>
                    <td v-if='this.state == "RANGE_EDIT" && this.edited_range == rewardPack'>
                        <input type="number" name="max_position" id="max_position" v-model="rewardPack.max_position" min="1">
                    </td>
                    <td v-else>{{ rewardPack.max_position }}</td>
                    <td v-if='this.state == "RANGE_EDIT" && this.edited_range == rewardPack'>
                        <div class="row">
                            <div class="col">
                                <button class="btn btn-outline-danger" @click="this.state='IDLE'">Cancel</button>
                            </div>
                            <div class="col">
                                <button class="btn btn-outline-primary" @click="this.applyEdit(rewardPack)">Apply</button>
                            </div>
                        </div>
                    </td>
                    <td v-else>
                        <div class="row">
                            <div class="col">
                                <button class="btn btn-outline-danger" @click="this.deleteReward(rewardPack)">Delete</button>
                            </div>
                            <div class="col">
                                <button class="btn btn-outline-primary" @click="this.editRange(rewardPack)">Edit</button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="this.state == 'IDLE'">
                <tr>
                    <td>
                        <button class="btn btn-primary" @click="this.addRewardsRange()">Add rewards range</button>
                    </td>
                    <td></td><td></td><td></td>
                </tr>
            </tfoot>
            <tfoot v-else-if="this.state == 'RANGE_CREATION'">
                <tr>
                    <td>
                        <table class="table table-striped table-sm">
                            <thead>
                                <th scope="col" style="width: 25%">Type</th>
                                <th scope="col">Value</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>XP</td>
                                    <td>
                                        <div class="input-group">
                                            <input type="number" class="form-control" name="value" id="value" placeholder="No XP" v-model="new_reward.xp">
                                            <button class="btn btn-outline-danger" type="button" id="button-addon2" @click="new_reward.xp = null">Clear</button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>e-Coins</td>
                                    <td>
                                        <div class="input-group">
                                            <input type="number" class="form-control" name="value" id="value" placeholder="No e-Coins" v-model="new_reward.ecoins">
                                            <button class="btn btn-outline-danger" type="button" id="button-addon2" @click="new_reward.ecoins = null">Clear</button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Custom Reward</td>
                                    <td>
                                        <div class="input-group">
                                            <input type="text" class="form-control" name="value" id="value" placeholder="No custom reward" v-model="new_reward.custom">
                                            <button class="btn btn-outline-danger" type="button" id="button-addon2" @click="new_reward.custom = null">Clear</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <input type="number" name="min_position" id="min_position" v-model="new_reward.min_position"  min="1">
                    </td>
                    <td>
                        <input type="number" name="max_position" id="max_position" v-model="new_reward.max_position" min="1">
                    </td>
                    <td>
                        <div class="row">
                            <div class="col">
                                <button class="btn btn-outline-danger" @click="this.state='IDLE'">Cancel</button>
                            </div>
                            <div class="col">
                                <button class="btn btn-outline-primary" @click="this.addToRewards()">Add</button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import { db } from '@/firebase';

export default {
    name: 'ChallengeRewards',
    props: ["context", "id"],
    data() {
        return {
            baseRequest: null,
            edited_range: null,
            rewards: [],
            new_reward: {
                min_position: 1,
                max_position: 2,
                xp: null,
                ecoins: null,
                custom: null
            },
            state: 'IDLE'
        }
    },
    async created() {
        this.baseRequest = db.collection('contexts').doc(this.context).collection('challenges').doc(this.id).collection('rewards').orderBy("min_position", "asc");
        this.getRewards(this.baseRequest);
    },
    methods: {
        async getRewards(req) {
            await req.get().then(docSnapshot => {
                this.rewards = [];
                docSnapshot.forEach(doc => {
                    const reward = doc.data();
                    reward.id = doc.id;
                    this.rewards.push(reward);
                });

            }, err => {
            console.log(`Encountered error: ${err}`);
            });
        },
        async addToRewards() {
            if (this.new_reward.xp == 0 || this.new_reward.xp == ''){
                this.new_reward.xp = null;
            }else{
                this.new_reward.xp = parseInt(this.new_reward.xp);
            }
            if (this.new_reward.ecoins == 0 || this.new_reward.ecoins == ''){
                this.new_reward.ecoins = null;
            }else{
                this.new_reward.ecoins = parseInt(this.new_reward.ecoins);
            }
            if (this.new_reward.custom == '' || this.new_reward.custom =='0'){
                this.new_reward.custom = null;
            }

            this.new_reward.min_position = parseInt(this.new_reward.min_position);
            this.new_reward.max_position = parseInt(this.new_reward.max_position);
            const rewardRef = db.collection('contexts').doc(this.context).collection('challenges').doc(this.id).collection('rewards');

            await rewardRef.add(this.new_reward);
            this.state='IDLE';
            this.new_reward.xp = null;
            this.new_reward.ecoins = null;
            this.new_reward.custom = null;
            this.getRewards(this.baseRequest);
        },
        addRewardsRange() {
            if (this.rewards.length > 0){
                this.new_reward.min_position = parseInt(this.rewards[this.rewards.length-1].max_position)+1;
                this.new_reward.max_position = parseInt(this.new_reward.min_position)+9;
            }else{
                this.new_reward.min_position = 1;
                this.new_reward.max_position = 1;
            }
            this.state = 'RANGE_CREATION';
        },
        editRange(range){
            console.log('Editing range '+range.min_position+' to '+range.max_position);
            this.edited_range = range;
            this.state = 'RANGE_EDIT';
        },
        async applyEdit(rewardPack){
            if (rewardPack.xp == 0 || this.new_reward.xp == ''){
                rewardPack.xp = null;
            }else{
                rewardPack.xp = parseInt(rewardPack.xp);
            }
            if (rewardPack.ecoins == 0 || this.new_reward.ecoins == ''){
                rewardPack.ecoins = null;
            }else{
                rewardPack.ecoins = parseInt(rewardPack.ecoins);
            }
            if (rewardPack.custom == '' || rewardPack.custom =='0'){
                rewardPack.ecoins = null;
            }

            rewardPack.min_position = parseInt(rewardPack.min_position);
            rewardPack.max_position = parseInt(rewardPack.max_position);
            const rewardRef = db.collection('contexts').doc(this.context).collection('challenges').doc(this.id).collection('rewards').doc(rewardPack.id);

            await rewardRef.set(rewardPack);
            this.state='IDLE';
            this.getRewards(this.baseRequest);
            alert('Reward edit confirmed');
        },
        async deleteReward(rewardPack){
            const rewardRef = db.collection('contexts').doc(this.context).collection('challenges').doc(this.id).collection('rewards').doc(rewardPack.id);
            await rewardRef.delete(rewardPack);
            this.getRewards(this.baseRequest);
            alert('Reward deleted');
        }
    }
}
</script>

<style>

</style>