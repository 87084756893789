import firebase from 'firebase'

var firebaseConfig = {
    apiKey: "AIzaSyCOVr4EFsJfNRz-52Tgh_35JjSyL7RnnSk",
    authDomain: "eiver-alpha.firebaseapp.com",
    databaseURL: "https://eiver-alpha.firebaseio.com",
    projectId: "eiver-alpha",
    storageBucket: "eiver-alpha.appspot.com",
    messagingSenderId: "971171483565",
    appId: "1:971171483565:web:e463a7c48ada92e73b1b24",
    measurementId: "G-BCDPPM59VL"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();
export const storage = firebaseApp.storage();
export const auth = firebaseApp.auth();
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);